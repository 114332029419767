
const  formatDateMonthDayYear = (timestamp:string) =>{
  const date = new Date(timestamp);
  const month = date.toLocaleString('en-US', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  let formattedTime = `${month} ${day}, ${year}`
  return formattedTime;
}

export {
  formatDateMonthDayYear,
};
