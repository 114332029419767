import iconGear from '../../images/icon-gear.svg';
import iconChatBubble from '../../images/icon-chat-bubble.svg';
import iconCreditCard from '../../images/icon-credit-card.svg';
import iconSignOut from '../../images/icon-x-circle.svg';
import iconSavedArticles from '../../images/icon-saved-articles.svg';
import iconBell from '../../images/icon-bell.svg';

const dashboardBaseUrl = '/dashboard'
const SITE_PAGES = {
  DashboardHome: {
    id: 'dashboard-home',
    slug: '',
    title: 'Dashboard',
    text: 'Dashboard',
    url: dashboardBaseUrl,
    icon: iconGear,
    alt: 'Gear icon',
    showInDashboardNavMenu: false,
  },
  MyAccount: {
    id: 'my-account',
    slug: '',
    // slug: 'my-account',
    title: 'My Account',
    text: 'My Account',
    url: dashboardBaseUrl,
    // url: `${dashboardBaseUrl}/my-account`,
    icon: iconGear,
    alt: 'Gear icon',
    showInDashboardNavMenu: true,
  },
  ChdUpdates: {
    id: 'chd-updates',
    slug: 'chd-updates',
    title: 'CHD Updates',
    text: 'CHD Updates',
    url: `${dashboardBaseUrl}/chd-updates`,
    icon: iconBell,
    alt: 'Bell icon',
    showInDashboardNavMenu: true,
  },
  MyDonations: {
    id: 'my-donations',
    slug: 'my-donations',
    title: 'My Donations',
    text: 'My Donations',
    url: `${dashboardBaseUrl}/my-donations`,
    icon: iconCreditCard,
    alt: 'Credit card icon',
    showInDashboardNavMenu: true,
  },
  CommunityForum: {
    id: 'community-forum',
    slug: 'community-forum',
    title: 'Community Forum',
    text: 'Community Forum',
    url: `${dashboardBaseUrl}/community-forum`,
    icon: iconChatBubble,
    alt: 'Chat bubble icon',
    showInDashboardNavMenu: true,
  },
  SavedArticles: {
    id: 'saved-articles',
    slug: 'saved-articles',
    title: 'Saved Articles',
    text: 'Saved Articles',
    url: `${dashboardBaseUrl}/saved-articles`,
    icon: iconSavedArticles,
    alt: 'Saved Articles Icon',
    showInDashboardNavMenu: true,
  },
  Login: {
    id: 'login',
    slug: 'login',
    title: 'Login',
    text: 'Login',
    url: '/login/',
    icon: '',
    alt: '',
    showInDashboardNavMenu: false,
  },
  Signup: {
    id: 'signup',
    slug: 'signup',
    title: 'Sign up',
    text: 'Sign up',
    url: '/signup',
    icon: '',
    alt: '',
    showInDashboardNavMenu: false,
  },
  Signout:{
    id: 'sign-out',
    slug: 'sign-out',
    title: 'Sign Out',
    text: 'Sign Out',
    url: '/sign-out/',
    icon: iconSignOut,
    alt: 'X inside of circle icon',
    showInDashboardNavMenu: false,
  },
  ResetPassword: {
    id: 'reset-password',
    slug: 'reset-password',
    title: 'Reset Password',
    text: 'Forgot Password',
    url: '/reset-password',
    icon: '',
    alt: '',
    showInDashboardNavMenu: false,
  },
}

export {
  dashboardBaseUrl,
  SITE_PAGES,
};